import { inject }                          from 'aurelia-framework';
import { PLATFORM }                        from 'aurelia-pal';
import { BaseFormViewModel }               from 'base-form-view-model';
import { BulletinRevisionFormSchema }      from 'modules/management/specifications/bulletins/bulletin-revisions/form-schema';
import { BulletinRevisionFilesRepository } from 'modules/management/specifications/bulletins/bulletin-revisions/services/files-repository';
import { FormSchema }                      from 'modules/management/specifications/bulletins/form-schema';
import { BulletinsRepository }             from 'modules/management/specifications/bulletins/services/repository';
import { AppContainer }                    from 'resources/services/app-container';

@inject(AppContainer, BulletinsRepository, FormSchema, BulletinRevisionFormSchema, BulletinRevisionFilesRepository)
export class CreateBulletin extends BaseFormViewModel {

    headerTitle = 'form.title.create-new-record';
    formId      = 'management.specifications.bulletins.creation-form';
    create      = true;

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     * @param bulletinRevisionFormSchema
     * @param bulletinRevisionFilesRepository
     */
    constructor(appContainer, repository, formSchema, bulletinRevisionFormSchema, bulletinRevisionFilesRepository) {
        super(appContainer);

        this.repository                      = repository;
        this.formSchema                      = formSchema;
        this.bulletinRevisionFormSchema      = bulletinRevisionFormSchema;
        this.bulletinRevisionFilesRepository = bulletinRevisionFilesRepository;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/specifications/bulletins/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return super.can([
            'management.specifications.bulletins.manage',
            'management.specifications.bulletins.create',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.schema       = this.formSchema.schema(this);
        this.globalSchema = this.formSchema.globalSchema(this);

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        this.initialModel.bulletin_revision = {};
        this.model.bulletin_revision        = {};

        this.nextBulletinRevisionModel = await this.fetchNextBulletinRevisionModel();

        this.model.bulletin_revision = this.bulletinRevisionFormSchema.model(0, this.nextBulletinRevisionModel);
        this.revisionFormSchema      = this.bulletinRevisionFormSchema.schema(this);
    }

    /**
     * Fetches next bulletin revision model
     *
     * @return {Promise<*>}
     */
    async fetchNextBulletinRevisionModel() {
        return await this.repository.nextBulletinRevisionModel();
    }

    /**
     * Submits view form
     *
     * @returns {Promise}
     */
    async submit() {
        this.alert = null;

        return await this.repository.create(this.model)
            .then((response) => {
                this.handleResponse(response, 'management.specifications.bulletins.index');
                this.initialModel.assign(this.model);
            })
            .catch((error) => console.error(error));
    }

}
